function fullHeight() {
    (function ($) {
        var heightBanner = $(window).outerHeight();
        $(".full-height").css("height", heightBanner);
        $(".min-fullheight").css("min-height", heightBanner);
        $('.half-height').css("height", heightBanner / 2);
        $('.nofull-height').css("height", heightBanner * 0.7);
    })(jQuery);
}

function fullsizeBanner(id) {
	var banner = jQuery(id);
	var image = banner.data('image-src');
	var video = banner.data('video-src');
	var mp4video = banner.data('mp4-src');

	banner.background({
		source: {
			poster: image,
			mp4: mp4video,
			video: video
		}
	});
}

function mobileNavToggle() {
	$(".drop-toggle").on('click', function(){
		$(this).closest('li').toggleClass('open');
	});
}
mobileNavToggle();

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});


jQuery(window).resize(function () {
    fullHeight();
});

$( document ).ready(function() {
	fullHeight();
	fullsizeBanner('#headerBackground');
});

